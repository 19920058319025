<template>
  <div class="container">
    <div class="flex-row-fluid ml-lg-8 pt-5">
      <img src="/media/custom/orbi-bds-horizontal.png" alt="ORBI" class="orbi-logo mb-5">
      <multiselect-form-group
        class="app-view__form-group mb-5 w-200px"
        :placeholder="$t('placeholder.choose_language')"
        :value="langOptions.find(el => el.id === lang)"
        :options="langOptions"
        @select="changeLanguage($event.id)"
      />

      <!--begin::Card-->
      <div class="card card-custom gutter-b">
        <div class="card-body p-0">
          <div class="row justify-content-center py-8 px-8 py-md-15 px-md-0">
            <div class="col-md-10">
              <div class="d-flex justify-content-between align-items-center pb-10 flex-column flex-md-row">
                <h1 class="display-4 text-uppercase font-weight-boldest m-0 order-2 order-md-1">{{ $t('title.booking_details') }}</h1>
                <div class="d-flex flex-column align-items-md-end px-0 mb-5 mb-md-0 order-1 order-md-2">
                  <a href="#">
                    <img src="/media/custom/orbi-bds-horizontal.png" alt="" style="height: 50px">
                  </a>
                </div>
              </div>
              <div class="border-bottom w-100"></div>
              <div class="row justify-content-between pt-6">
                <div class="col-sm-3 text-center text-sm-left">
                  <div class="d-flex flex-column flex-root">
                    <span class="font-weight-bolder mb-2">{{ $t('label.apartment') }}</span>
                    <span class="opacity-70">{{ qrBookingData.apartment_id }}</span>
                  </div>
                </div>
                <div class="col-sm-3 text-center text-sm-left">
                  <div class="d-flex flex-column flex-root">
                    <span class="font-weight-bolder mb-2">{{ $t('label.income_date') }}</span>
                    <span class="opacity-70">{{ qrBookingData.income_date }}</span>
                  </div>
                </div>
                <div class="col-sm-3 text-center text-sm-left">
                  <div class="d-flex flex-column flex-root">
                    <span class="font-weight-bolder mb-2">{{ $t('label.exit_date') }}</span>
                    <span class="opacity-70">{{ qrBookingData.exit_date }}</span>
                  </div>
                </div>
                <div class="col-sm-3 text-center text-sm-left">
                  <div class="d-flex flex-column flex-root">
                    <span class="font-weight-bolder mb-2">{{ $t('label.status') }}</span>
                    <span class="text-uppercase opacity-70" :class="`text-${statusColor}`">{{ qrBookingData.status }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center bg-gray-100 pt-8 px-8 pt-md-10 px-md-0 pb-15 mx-0">
            <div class="col-md-10">
              <div class="row justify-content-between pt-6">
                <div class="col-sm-3 text-center text-sm-left px-0">
                  <div class="d-flex flex-column flex-root">
                    <span class="text-muted text-uppercase mb-2">{{ $t('label.full_name') }}</span>
                    <span class="text-dark-75 font-weight-bolder">{{ qrBookingData.full_name }}</span>
                  </div>
                </div>
                <div class="col-sm-3 text-center text-sm-left px-0">
                  <div class="d-flex flex-column flex-root">
                    <span class="text-muted text-uppercase mb-2">{{ $t('label.email') }}</span>
                    <span class="text-dark-75 font-weight-bolder">{{ qrBookingData.email }}</span>
                  </div>
                </div>
                <div class="col-sm-3 text-center text-sm-left px-0">
                  <div class="d-flex flex-column flex-root">
                    <span class="text-muted text-uppercase mb-2">{{ $t('label.phone_number') }}</span>
                    <span class="text-dark-75 font-weight-bolder">{{ qrBookingData.phone_number }}</span>
                  </div>
                </div>
                <div class="col-sm-2 text-center text-sm-left px-0">
                  <div class="d-flex flex-column flex-root">
                    <span class="text-muted text-uppercase mb-2">{{ $t('label.adults') }}</span>
                    <span class="text-dark-75 font-weight-bolder">{{ qrBookingData.adults }}</span>
                  </div>
                </div>
                <div class="col-sm-1 text-center text-sm-left px-0">
                  <div class="d-flex flex-column flex-root">
                    <span class="text-muted text-uppercase mb-2">{{ $t('label.children') }}</span>
                    <span class="text-dark-75 font-weight-bolder">{{ qrBookingData.children }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="canCancelBooking" class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
            <div class="col-md-10">
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-danger font-weight-bold" @click="cancelBooking">
                  {{ $t('btn.cancel_booking') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import i18nService from '@/core/services/i18n.service';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup.vue';

export default {
  components: {
    MultiselectFormGroup
  },
  data: () => ({
    lang: 'en',
    languages: i18nService.languages,
    bookingId: null,
    token: null
  }),
  beforeMount() {
    const {booking_id, token, lang} = this.$route.query;
    this.bookingId = booking_id;
    this.token = token;
    this.lang = lang;

    if (lang) {
      this.changeLanguage(lang);
    }

    if (!this.bookingId || !this.token) return;
    this.getBookingData();
  },
  computed: {
    ...mapGetters({
      qrBookingData: 'bookingsStore/QR_BOOK_DATA'
    }),
    queryData() {
      return {booking_id: this.bookingId, token: this.token, lang: this.lang};
    },
    canCancelBooking() {
      return Object.keys(this.qrBookingData).length && this.qrBookingData.status !== 'canceled';
    },
    langOptions() {
      return this.languages.map(item => {
        return {id: item.lang, label: this.$t(`lang.${item.lang}`)};
      });
    },
    statusColor() {
      let result = 'secondary';

      switch (this.qrBookingData.status) {
        case 'approved':
          result = 'success';
          break;
        case 'new':
          result = 'warning';
          break;
        case 'canceled':
          result = 'danger';
          break;
      }

      return result;
    }
  },
  methods: {
    getBookingData() {
      this.$store.dispatch('bookingsStore/GET_QR_BOOK_DATA', this.queryData);
    },
    cancelBooking() {
      this.$store.dispatch('bookingsStore/CANCEL_QR_BOOK', this.queryData).then(() => {this.getBookingData()});
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.$i18n.locale = lang;
    }
  }
}
</script>

<style lang="scss" scoped>
.orbi-logo {
  width: 25%;
}

table {
  th, td {
    white-space: nowrap;
  }
}
</style>